// Print
import {
  ClientPrintJob,
  NetworkPrinter,
  JSPrintManager,
  WSStatus,
  InstalledPrinter,
} from "jsprintmanager";
import { ORDER_TYPES } from "../../constants";
import {
  DIVIDER,
  ExternalOrderSummary,
  CommandEscPos,
  InvoiceESCPOS,
} from "./PrintHelper";
import { SendIssue } from "../../services";
import { P, ReadParameter } from "../../business";

import EscPosEncoder from "esc-pos-encoder";
// import { default as EscPosEncoder } from "@manhnd/esc-pos-encoder";

const trueFallback = () => true;
const falseFallback = () => false;

// ESC/POS Commands
const esc = "\x1B"; //ESC byte in hex notation
const newLine = "\x0A"; //LF byte in hex notation
const DoubleOn = esc + "!" + "\x28";
const DoubleOff = esc + "!" + "\x00";

function PrintLogo() {
  return ReadParameter(P.print_logo) === "true";
}

/**
 *
 * @returns
 */
export function GetInstalledPrinters(): Promise<unknown> {
  if (JSPrintManager.websocket_status == WSStatus.Open)
    return JSPrintManager.getPrinters().then((e) => e);

  JSPrintManager.auto_reconnect = true;
  JSPrintManager.license_url =
    "https://www.neodynamic.com/licenses/jspm/v5/fooddynamic";

  const prm = JSPrintManager.start().then(() => {
    const printers = JSPrintManager.getPrinters().then((e) => e);
    return printers;
  });
  return prm;
}

/**
 *
 * @param name
 * @returns
 */
async function CheckInstalledPrinter(name: string) {
  const printers: any = await GetInstalledPrinters();
  return printers.findIndex((p) => p == name) > -1;
}

/**
 *
 * @param ip
 * @param port
 * @param name
 * @returns
 */
async function StartPrinter(ip: string, port: string, name: string) {
  let printer;

  if (ip == "" || ip == null) {
    const ok = await CheckInstalledPrinter(name);
    if (ok) printer = new InstalledPrinter(name);
  } else printer = new NetworkPrinter(parseInt(port), ip);
  return printer;
}

/**
 *
 * @param ip
 * @param port
 * @param name
 * @returns
 */
export async function OpenCashDrawer(ip: string, port: string, name: string) {
  if (JSPrintManager.websocket_status == WSStatus.Closed) return false;
  if (JSPrintManager.websocket_status == WSStatus.Blocked) return false;
  if (JSPrintManager.websocket_status == WSStatus.Open) {
    const printer = await StartPrinter(ip, port, name);
    if (printer == null || printer == undefined) return false;

    const printJob = new ClientPrintJob();
    printJob.clientPrinter = printer;

    var cmds = esc + "\x70" + "\x00" + "\x0F" + "\x0F"; // Open drawer PIN 2
    cmds += "\x70" + "\x01" + "\x0F" + "\x0F"; // Open 2nd drawer PIN 5
    printJob.printerCommands = cmds;
    printJob.sendToClient();

    console.log("openCashDrawer: print job has been sent");
    return true;
  }
}

export async function printCommand(
  date,
  time,
  orderId,
  orderType,
  printers = [],
  current,
  products,
  _order_data,
  _people_number,
  _comment
) {
  if (JSPrintManager.websocket_status == WSStatus.Open) {
    if (products.length === 0)
      console.log("printCommand: there are no products to print");

    if (JSPrintManager.websocket_status == WSStatus.Open) {
      const printer = await StartPrinter(
        printers[current].ip,
        printers[current].port,
        printers[current].name
      );
      if (printer == null || printer == undefined) {
        console.warn(
          "Printer '" + printers[current].name + "' is not installed"
        );
        return;
      }
      const printJob = new ClientPrintJob();
      printJob.clientPrinter = printer;

      // Todo: mover al helper
      const tipoPedido =
        orderType === ORDER_TYPES.DELIVERY
          ? "Reparto"
          : orderType == ORDER_TYPES.TAKEAWAY
          ? "Recogida"
          : "Mesa " + orderType;

      const cmds = CommandEscPos(
        date,
        time,
        orderId,
        tipoPedido,
        products,
        _order_data,
        _people_number,
        _comment
      );

      printJob.printerCommands = cmds;

      const finished = printJob.sendToClient().then(() => {
        if (current < printers.length - 1) {
          console.log("printCommand: call again for the next printer");
          printCommand(
            date,
            time,
            orderId,
            orderType,
            printers,
            current + 1,
            products,
            _order_data,
            _people_number,
            _comment
          );
          return false;
        } else {
          console.log("printCommand: stopping recursion");
          return true;
        }
      });

      return finished;
    } else if (JSPrintManager.websocket_status == WSStatus.Closed) {
      console.error("printCommand: WS is closed");
      return false;
    } else if (JSPrintManager.websocket_status == WSStatus.Blocked) {
      console.error("printCommand: WS is blocked");
      return false;
    }

    return new Promise((resolve, reject) => resolve(true)).then(() => true);
  }
}

/**
 *
 * @param {*} date Current date
 * @param {*} time Current time
 * @param {*} orders OrderResponseCocina[]
 * @param {*} category_printers Printers and its associated categories
 * @param {*} cur_order
 * @param {*} cur_category
 * @param {*} current_printer
 * @returns
 */
export async function printerDaemon(
  date,
  time,
  orders = [],
  category_printers = [],
  cur_order,
  cur_category,
  current_printer
) {
  console.log("Running printerDaemon");

  // Si se intenta acceder a un indice de pedido mayor al array de pedidos
  if (cur_order >= orders.length || cur_order < 0) {
    console.log("printerDaemon: no more orders");
    return false;
  }

  // Si se intenta acceder a un indice de pedido mayor al array de pedidos
  if (
    category_printers[current_printer] == undefined ||
    cur_category >= category_printers[current_printer].length ||
    cur_category < 0
  ) {
    console.log("printerDaemon: no more category printers");
    return false;
  }

  // Si se intenta acceder a un indice de impresora mayor al array de impresoras
  if (current_printer >= category_printers.length) {
    console.log("printerDaemon: no more categories");
    return false;
  }

  //>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> Datos del pedido
  const orderInternalId = orders[cur_order].internal_id;
  const orderType = orders[cur_order].type;
  const orderTable = orders[cur_order].table_id;
  const orderProducts = orders[cur_order].products;
  const orderData = orders[cur_order].order_data;
  const peopleNumber = orders[cur_order].people_number;
  const comment = orders[cur_order].general_comment;
  const tipoPedido =
    orderType === ORDER_TYPES.DELIVERY
      ? "Reparto"
      : orderType === ORDER_TYPES.TAKEAWAY
      ? "Recogida"
      : "Mesa " + orderTable;

  //>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> Relación categoría-impresoras

  // Obtener productos a imprimir de la categoría actual
  const cat_prods = orderProducts.filter((p) =>
    category_printers[current_printer].categories.some(
      (it) => it == p.category_id
    )
  );

  if (cat_prods.length > 0) {
    // Si hay productos e impresoras, se imprime
    if (JSPrintManager.websocket_status == WSStatus.Open) {
      const printer = await StartPrinter(
        category_printers[current_printer].ip,
        category_printers[current_printer].port,
        category_printers[current_printer].name
      );

      if (printer == null || printer == undefined) {
        console.warn(
          "Printer '" +
            category_printers[current_printer].name +
            "' is not installed"
        );
        SendIssue(
          "AUTO",
          "Printer '" +
            category_printers[current_printer].name +
            "' is not installed"
        );
        return;
      }

      // Inicializar printer job
      const printJob = new ClientPrintJob();
      printJob.clientPrinter = printer;

      const cmds = CommandEscPos(
        date,
        time,
        orderInternalId,
        tipoPedido,
        cat_prods,
        orderData,
        peopleNumber,
        comment
      );

      printJob.printerCommands = cmds;

      printJob.onError = (data, v) => {
        SendIssue(
          "PrintController::printerDaemon::onError = ",
          JSON.stringify(printJob.clientPrinter) +
            " --- " +
            JSON.stringify(data)
        );
      };

      const finished = printJob
        .sendToClient()
        .then(() => {
          console.log("printerDaemon: print job has been sent");
          if (current_printer < category_printers.length - 1) {
            const next_printer = current_printer + 1;
            console.log("printerDaemon: recursion to next printer");
            printerDaemon(
              date,
              time,
              orders,
              category_printers,
              cur_order,
              cur_category,
              next_printer
            );

            return false;
          }
          // Se pasa al siguiente pedido
          if (current_printer >= category_printers.length - 1) {
            const next_order = cur_order + 1;
            console.log("printerDaemon: recursion to next order");
            printerDaemon(
              date,
              time,
              orders,
              category_printers,
              parseInt(next_order),
              0,
              0
            );
            return false;
          }
        })
        .finally(() => {
          return true;
        })
        .catch((e) => {
          console.error("printerDaemon: exception ocurred during printing");
          return false;
        });

      return finished;
    } else SendIssue("AUTO", "JSPM WS is not connected");

    return true;
  } else {
    // console.warn(
    //   "printerDaemon: cat_prods length is <= 0 {" + cat_prods.length + "}"
    // );

    if (current_printer < category_printers.length - 1) {
      const next_printer = current_printer + 1;
      console.log("printerDaemon (2nd block): recursion to next printer");
      printerDaemon(
        date,
        time,
        orders,
        category_printers,
        cur_order,
        cur_category,
        next_printer
      );

      return false;
    }
    // Se pasa al siguiente pedido
    if (current_printer >= category_printers.length - 1) {
      const next_order = cur_order + 1;
      console.log("printerDaemon (2nd block): recursion to next order");
      printerDaemon(
        date,
        time,
        orders,
        category_printers,
        parseInt(next_order),
        0,
        0
      );

      return false;
    }
  }
}

export async function ExternalDaemon(
  date,
  time,
  orders = [],
  delivery_printers = [],
  takeaway_printers = [],
  cur_order,
  current_printer
) {
  if (cur_order >= orders.length || cur_order < 0) {
    console.log("ExternalDaemon: can't find current order {" + cur_order + "}");
    return false;
  }

  if (
    orders[cur_order].type == "DELIVERY" &&
    current_printer >= delivery_printers.length
  ) {
    console.log("ExternalDaemon: can't find any Delivery printers");
    return false;
  }

  if (
    orders[cur_order].type == "TAKEAWAY" &&
    current_printer >= takeaway_printers.length
  ) {
    console.log("ExternalDaemon: can't find any Takeaway printers");
    return false;
  }

  //>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> Pedido
  const internalId = orders[cur_order].internal_id;
  const type = orders[cur_order].type;
  const table = orders[cur_order].table_id;
  const products = orders[cur_order].products;
  const menus = orders[cur_order].menus || [];
  const orderData = orders[cur_order].order_data;
  const total = orders[cur_order].total_price;
  const tipoPedido =
    type === "DELIVERY"
      ? "Reparto"
      : type == "TAKEAWAY"
      ? "Recogida"
      : "Mesa " + table;

  // Si hay productos e impresoras, se imprime
  if (products.length > 0) {
    if (JSPrintManager.websocket_status == WSStatus.Open) {
      const _printer =
        type == "DELIVERY"
          ? delivery_printers[current_printer]
          : takeaway_printers[current_printer];

      const printer = await StartPrinter(
        _printer.ip,
        _printer.port,
        _printer.name
      );

      if (printer == null || printer == undefined) {
        console.warn("Printer '" + _printer.name + "' is not installed");
        SendIssue("AUTO", "Printer '" + _printer.name + "' is not installed");
        return;
      }

      // Inicializar printer job
      const printJob = new ClientPrintJob();
      printJob.clientPrinter = printer;
      printJob.onError = (data, v) => {
        SendIssue(
          "ExternalDaemon::onError = ",
          JSON.stringify(printJob.clientPrinter) + " - " + JSON.stringify(data)
        );
      };

      const cmds = InvoiceESCPOS(
        date,
        time,
        null,
        internalId,
        total,
        products.filter((op) => op.menu_id == null),
        menus,
        tipoPedido,
        0,
        null,
        null,
        orderData,
        orderData
      );

      printJob.printerCommands = cmds;

      const finished = printJob
        .sendToClient()
        .then(() => {
          console.log("ExternalDaemon: print job has been sent");
          const printer_array =
            type == "DELIVERY" ? delivery_printers : takeaway_printers;
          if (current_printer < printer_array.length - 1) {
            const next_printer = current_printer + 1;
            console.log("ExternalDaemon: recursion to next printer");

            ExternalDaemon(
              date,
              time,
              orders,
              delivery_printers,
              takeaway_printers,
              cur_order,
              next_printer
            );
          }
          // Se pasa al siguiente pedido
          else if (current_printer >= printer_array.length - 1) {
            const next_order = cur_order + 1;
            console.log("ExternalDaemon: recursion to next order");

            ExternalDaemon(
              date,
              time,
              orders,
              delivery_printers,
              takeaway_printers,
              next_order,
              0
            );
          } else return true; // End recursion
        })
        .finally(() => true)
        .catch((e) => {
          SendIssue("ExternalDaemon Catch", JSON.stringify(e));
          return false;
        });

      return finished;
    }

    return true;
  } else {
    console.log("printerCustomDaemon: the order has no products");
    const printer_array =
      type == "DELIVERY" ? delivery_printers : takeaway_printers;
    if (current_printer < printer_array.length - 1) {
      const next_printer = current_printer + 1;
      console.log("printerCustomDaemon (2nd block): recursion to next printer");
      ExternalDaemon(
        date,
        time,
        orders,
        delivery_printers,
        takeaway_printers,
        cur_order,
        next_printer
      );
    }
    // Se pasa al siguiente pedido
    else if (current_printer >= printer_array.length - 1) {
      const next_order = cur_order + 1;
      console.log("printerCustomDaemon (2nd block): recursion to next order");
      ExternalDaemon(
        date,
        time,
        orders,
        delivery_printers,
        takeaway_printers,
        next_order,
        0
      );
    }
  }
}

/**
 *
 * @param dateTo
 * @param dateFrom
 * @param total
 * @param cash
 * @param card
 * @param plataforma
 * @param prnt
 * @returns
 */
export async function printCaja(
  dateTo,
  dateFrom,
  total,
  cash,
  card,
  plataforma,
  prnt
) {
  if (JSPrintManager.websocket_status == WSStatus.Open) {
    const printer = await StartPrinter(prnt.ip, prnt.port, prnt.name);
    if (printer == null || printer == undefined) {
      console.warn("Printer '" + prnt.name + "' is not installed");
      return;
    }
    const printJob = new ClientPrintJob();
    printJob.clientPrinter = printer;

    const cmds = await GenerateTotalesESCPOS(
      dateTo,
      dateFrom,
      total,
      cash,
      card,
      plataforma
    );

    printJob.printerCommands = cmds;
    const finished = printJob.sendToClient();

    return finished;
  } else if (JSPrintManager.websocket_status == WSStatus.Closed) {
    console.log("JSPM is not installed or not running!");
    return new Promise((resolve, reject) => resolve(false)).then(() => false);
  } else if (JSPrintManager.websocket_status == WSStatus.Blocked) {
    console.log("JSPM has blocked this website!");
    new Promise((resolve, reject) => resolve(false)).then(() => false);
  } else {
    console.log(JSPrintManager.websocket_status);
    new Promise((resolve, reject) => resolve(false)).then(() => false);
  }

  return new Promise((resolve, reject) => resolve(true)).then(() => true);
}

/**
 *
 * @param dateTo
 * @param dateFrom
 * @param total
 * @param cash
 * @param card
 * @param plataforma
 * @returns
 */
async function GenerateTotalesESCPOS(
  dateTo,
  dateFrom,
  total,
  cash,
  card,
  plataforma
) {
  // Inicializar impresora
  var cmds = esc + "@";
  cmds += esc + "!" + "\x00"; //Character font A selected (ESC ! 0)
  cmds += esc + "t" + "\x02";

  // Nombre del restaurante
  cmds += esc + "a" + "\x01";
  cmds += "__ Resumen contable __";
  cmds += newLine + newLine;
  cmds += esc + "a" + "\x00";

  // Datos de pedido
  cmds += "Ultimo cierre:   " + dateFrom;
  cmds += newLine;
  cmds += "Fecha actual:    " + dateTo;
  cmds += newLine;
  cmds += esc + "a" + "\x01";
  cmds += DIVIDER;
  cmds += newLine;
  cmds += esc + "a" + "\x00";
  cmds += newLine;

  cmds += "Total             " + parseFloat(total.toString()).toFixed(2);
  cmds += newLine;
  cmds += "Efectivo          " + parseFloat(cash.toString()).toFixed(2);
  cmds += newLine;
  cmds += "Tarjeta           " + parseFloat(card.toString()).toFixed(2);
  cmds += newLine;
  cmds += "App               " + parseFloat(plataforma.toString()).toFixed(2);
  cmds += newLine;
  cmds += newLine + newLine + DoubleOff;
  cmds += newLine + newLine;

  cmds += esc + "d" + "\x02"; // Feed 2 lines
  cmds += esc + "m"; // Cut

  return cmds;
}

export function LogoImageWrapper(
  cmds: string,
  printJob: ClientPrintJob,
  onFinished?: Function
) {
  console.log(ReadParameter(P.printable_logo));
  if (
    !PrintLogo() ||
    ReadParameter(P.printable_logo) == "" ||
    ReadParameter(P.printable_logo) == "undefined"
  ) {
    printJob.printerCommands = cmds;
    printJob.sendToClient();
    return;
  }

  let encoder = new EscPosEncoder({});
  let img = new Image();
  img.src = `${ReadParameter(P.printable_logo)}${"?"}${new Date().getTime()}`;
  img.crossOrigin = "anonymous";
  img.setAttribute("crossOrigin", "");

  img.onload = function () {
    let result = encoder
      .align("center")
      .image(img, 320, 120, "atkinson")
      .newline()
      .encode();

    printJob.binaryPrinterCommands = result;
    printJob.sendToClient();
    printJob.printerCommands = cmds;
    printJob.sendToClient().then(() => (onFinished ? onFinished() : undefined));

    // if (onFinished) printJob.onFinished(() => onFinished());
  };
}

export async function TestPrinter(ip, port, name) {
  if (JSPrintManager.websocket_status == WSStatus.Open) {
    const printer = await StartPrinter(ip, port, name);
    if (printer == null || printer == undefined)
      return console.warn("Printer '" + name + "' is not installed");

    const printJob = new ClientPrintJob();
    printJob.clientPrinter = printer;

    var cmds = esc + "!" + "\x00"; //Character font A selected (ESC ! 0)
    cmds += esc + "t" + "\x02";
    cmds += esc + "a" + "\x01";
    cmds += newLine + DIVIDER + newLine;
    cmds += "__ IMPRESORA DETECTADA __";
    cmds += newLine + DIVIDER + newLine;
    cmds += esc + "a" + "\x00";
    cmds += newLine + newLine + DoubleOff;
    cmds += newLine + newLine;
    cmds += esc + "d" + "\x02"; // Feed 2 lines
    cmds += esc + "m"; // Cut

    LogoImageWrapper(cmds, printJob);
  } else if (JSPrintManager.websocket_status == WSStatus.Closed) {
    console.log("JSPM is not installed or not running!");
    return new Promise((resolve, reject) => resolve(false)).then(() => false);
  } else if (JSPrintManager.websocket_status == WSStatus.Blocked) {
    console.log("JSPM has blocked this website!");
    new Promise((resolve, reject) => resolve(false)).then(() => false);
  } else {
    console.log(JSPrintManager.websocket_status);
    new Promise((resolve, reject) => resolve(false)).then(() => false);
  }

  return new Promise((resolve, reject) => resolve(true)).then(() => true);
}

export async function printMessages(
  messages = [],
  printers = [],
  current_message,
  current_printer
) {
  if (current_printer >= printers.length) {
    console.log(
      `printMessages: current_printer (${current_printer}) >= printers.length (${printers.length})`
    );
    return false;
  }

  if (current_message >= messages.length) {
    console.log(
      `printMessages: current_message (${current_message}) >= messages.length (${messages.length})`
    );
    return false;
  }

  if (JSPrintManager.websocket_status == WSStatus.Open) {
    const cp = printers[current_printer];

    const printer = await StartPrinter(cp.ip, cp.port, cp.name);

    if (printer == null || printer == undefined)
      return console.warn("Printer '" + cp.name + "' is not installed");

    // Inicializar printer job
    const printJob = new ClientPrintJob();
    printJob.clientPrinter = printer;

    let date = new Date();

    let cmds = "";
    cmds += esc + "a" + "\x01";
    cmds += date.toLocaleDateString() + " - " + date.toLocaleTimeString();
    cmds += newLine + newLine;
    cmds += ".".repeat(40);
    cmds += newLine + newLine;

    cmds += DoubleOn + messages[current_message].text + DoubleOff;
    cmds += newLine + newLine;
    cmds += esc + "a" + "\x00";
    cmds += newLine + newLine;
    cmds += newLine + newLine;
    cmds += newLine + newLine + DoubleOff;
    cmds += esc + "m"; // Feed 2 lines
    cmds += esc + "d" + "\x02"; // Cut
    printJob.printerCommands = cmds;

    const finished = printJob
      .sendToClient()
      .then(() => {
        // console.debug("printMessages: print job has been sent");
        if (current_printer < printers.length - 1) {
          // console.debug("printMessages: recursion to next printer");
          printMessages(
            messages,
            printers,
            current_message,
            current_printer + 1
          );
        }
        // Se pasa al siguiente mensaje
        else if (current_printer >= printers.length - 1) {
          // console.debug("printMessages: recursion to next message");
          printMessages(messages, printers, current_message + 1, 0);
        } else {
          // console.debug("Ending printMessages");
          return true;
        }
      })
      .finally(trueFallback)
      .catch(falseFallback);

    return finished;
  }

  return true;
}

/**
 *
 * @param orders_type
 * @param products
 * @param category_printers
 * @param current_category
 * @param current_printer
 * @returns
 */
export async function printExternalOrdersList(
  orders_type,
  products = [],
  category_printers,
  current_category,
  current_printer
) {
  if (current_printer >= category_printers.length) return false;
  if (category_printers[current_printer] == undefined) return false;
  if (current_category >= category_printers[current_printer].length)
    return false;
  if (current_category < 0) return false;

  if (JSPrintManager.websocket_status !== WSStatus.Open)
    return console.error("printExternalOrdersList: JSPM WS is not open");

  const category_products = products.filter((p) =>
    category_printers[current_printer].categories.some(
      (it) => it == p.category_id
    )
  );

  if (category_products.length > 0) {
    const printer = await StartPrinter(
      category_printers[current_printer].ip,
      category_printers[current_printer].port,
      category_printers[current_printer].name
    );

    if (printer == null || printer == undefined) return;

    // Inicializar printer job
    const printJob = new ClientPrintJob();
    printJob.clientPrinter = printer;
    console.log("current_category = " + current_category);
    console.log("current_printer = " + current_printer);
    const date = new Date();
    const cmds = ExternalOrderSummary(
      date.toLocaleDateString() + " - " + date.toLocaleTimeString(),
      orders_type,
      category_products
    );

    printJob.printerCommands = cmds;

    const finished = printJob
      .sendToClient()
      .then(() => {
        if (current_printer < category_printers.length - 1) {
          printExternalOrdersList(
            orders_type,
            products,
            category_printers,
            current_category,
            current_printer + 1
          );

          return false;
        }
        // if (current_printer >= category_printers.length - 1) {
        //   printExternalOrdersList(
        //     orders_type,
        //     products,
        //     category_printers,
        //     current_category + 1,
        //     current_printer
        //   );
        //   return false;
        // }
      })
      .catch((e) => {
        return false;
      });

    return finished;
  }
  // else {
  //   if (current_printer < category_printers.length - 1) {
  //     printExternalOrdersList(
  //       orders_type,
  //       products,
  //       category_printers,
  //       current_category,
  //       current_printer + 1
  //     );

  //     return false;
  //   }
  // }

  return;
}

/**
 * Prints an order's invoice
 * @param invoiceId
 * @param internalId
 * @param total
 * @param products
 * @param menus
 * @param type
 * @param table
 * @param peopleNumber
 * @param comment
 * @param customer
 * @param orderData
 * @param order
 * @param printers
 * @param current
 * @param d
 * @returns
 */
export async function PrintOrderInvoice(
  invoiceId: string | null,
  internalId: string | null,
  total: number | null,
  products = [],
  menus = [],
  type: string | null,
  table: string | null,
  peopleNumber: number | null,
  comment: string | null,
  customer: any | null,
  orderData: any | null,
  order: any | null,
  printers = [],
  current: number,
  d?: Date,
  partial?: boolean,
  partialAmount?: number,
  partialPaymentMethod?: string
) {
  if (JSPrintManager.websocket_status == WSStatus.Closed) {
    console.error("PrintOrderInvoice: WS is closed");
    return false;
  } else if (JSPrintManager.websocket_status == WSStatus.Blocked) {
    console.error("PrintOrderInvoice: WS is blocked");
    return false;
  }

  if (products.length === 0 && menus.length === 0)
    console.warn("PrintOrderInvoice: there are no products or menus to print");

  const printer = await StartPrinter(
    printers[current].ip,
    printers[current].port,
    printers[current].name
  );

  if (printer == null || printer == undefined)
    return console.error(
      "Printer '" + printers[current].name + "' is not installed"
    );

  const printJob = new ClientPrintJob();
  printJob.clientPrinter = printer;

  // TODO: mover al helper
  const tipoPedido =
    type === ORDER_TYPES.DELIVERY
      ? "Reparto"
      : type == ORDER_TYPES.TAKEAWAY
      ? "Recogida"
      : "Mesa " + table;

  const now = d ? d : new Date();

  const cmds = InvoiceESCPOS(
    now.toLocaleDateString(),
    now.toLocaleTimeString(),
    invoiceId,
    internalId,
    total,
    products,
    menus,
    tipoPedido,
    peopleNumber,
    comment,
    customer,
    orderData,
    order,
    partial,
    partialAmount,
    partialPaymentMethod
  );

  // printJob.printerCommands = cmds;
  LogoImageWrapper(cmds, printJob, () => {
    if (current >= printers.length - 1) {
      console.log("PrintOrderInvoice: stopping recursion");
      return true;
    }

    console.log("PrintOrderInvoice: call again for the next printer");
    PrintOrderInvoice(
      invoiceId,
      internalId,
      total,
      products,
      menus,
      type,
      table,
      peopleNumber,
      comment,
      customer,
      orderData,
      order,
      printers,
      current + 1
    );
    return false;
  });

  // const finished = printJob.sendToClient().then(() => {
  //   if (current >= printers.length - 1) {
  //     console.log("PrintOrderInvoice: stopping recursion");
  //     return true;
  //   }

  //   console.log("PrintOrderInvoice: call again for the next printer");
  //   PrintOrderInvoice(
  //     invoiceId,
  //     internalId,
  //     total,
  //     products,
  //     menus,
  //     type,
  //     table,
  //     peopleNumber,
  //     comment,
  //     customer,
  //     orderData,
  //     order,
  //     printers,
  //     current + 1
  //   );
  //   return false;
  // });

  return true;
  // return finished;
}
