import { OverviewProductResponse, PinCheckResponse } from "../services";
import {
  Category,
  CategoryPrinter,
  Customer,
  RestaurantParameters,
  RestaurantPrinter,
  RestaurantPrintingParameters,
  Space,
} from "./DataModel";

export function makeUid(length: number) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result + "-" + ReadParameter("slug");
}

/**
 * Get the value of a cookie
 * Source: https://gist.github.com/wpsmith/6cf23551dd140fb72ae7
 * @param  {string} name  The name of the cookie
 * @return {string}       The cookie value
 */
export function getCookie(name: string): string {
  let value = `; ${document.cookie}`;
  let parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

/** User parameters */
export enum UP {
  user_id = "user_id",
  user_name = "user_name",
  user_color = "user_color",
  enable_back_office = "enable_back_office",
  enable_close_order = "enable_close_order",
  enable_edit_order = "enable_edit_order",
  enable_edit_menu = "enable_edit_menu",
  enable_accounting = "enable_accounting",
  enable_edit_parameters = "enable_edit_parameters",
  enable_cancel_order = "enable_cancel_order",
}

/** Business parameters */
export enum P {
  address = "address",
  cached_categories = "cached_categories",
  cached_customers = "cached_customers",
  cached_printers = "cached_printers",
  cached_products = "cached_products",
  cached_spaces = "cached_spaces",
  categories_printers = "categories_printers",
  cif = "cif",
  city = "city",
  custom_text = "custom_text",
  default_printers = "default_printers",
  domain = "domain",
  email = "email",
  enable_autoprint = "enable_autoprint",
  enable_card = "enable_card",
  enable_cash = "enable_cash",
  enable_delivery = "enable_delivery",
  enable_live_state = "enable_live_state",
  enable_orders = "enable_orders",
  enable_reservation = "enable_reservation",
  enable_show_offers = "enable_show_offers",
  enable_stocks = "enable_stocks",
  enable_stripe = "enable_stripe",
  enable_takeaway = "enable_takeaway",
  enable_waiting_time = "enable_waiting_time",
  filtered_tables = "filtered_tables",
  font_size = "font_size",
  font_size_commands = "font_size_commands",
  // font_size_metadata = "font_size_metadata",
  legal_name = "legal_name",
  line_height = "line_height",
  line_height_commands = "line_height_commands",
  max_commands = "max_commands",
  min_delivery = "min_delivery",
  name = "name",
  phone = "phone",
  print_attributes = "print_attributes",
  print_cancel = "print_cancel",
  print_categories = "print_categories",
  print_categories_commands = "print_categories_commands",
  print_comments = "print_comments",
  print_edit = "print_edit",
  print_logo = "print_logo",
  print_menu_products = "print_menu_products",
  print_on_checkout = "print_on_checkout",
  printable_logo = "printable_logo",
  region = "region",
  selected_space = "selected_space",
  session_token = "session_token",
  slug = "slug",
  supplement_delivery = "supplement_delivery",
  tables = "tables",
  terminal_ip = "terminal_ip",
  time_commands = "time_commands",
  time_delivery = "time_delivery",
  time_takeaway = "time_takeaway",
  type = "type",
  unify_products = "unify_products",
  view = "view",
  zip_code = "zip_code",
}

export function LoadBusinessData(
  name: string,
  email: string,
  phone: string,
  cif: string,
  address: string,
  legal_name: string,
  city: string,
  region: string,
  zip_code: string
) {
  localStorage.setItem(P.address, address);
  localStorage.setItem(P.name, name);
  localStorage.setItem(P.email, email);
  localStorage.setItem(P.phone, phone);
  localStorage.setItem(P.cif, cif);
  localStorage.setItem(P.legal_name, legal_name);
  localStorage.setItem(P.city, city);
  localStorage.setItem(P.region, region);
  localStorage.setItem(P.zip_code, zip_code);
}

/**
 * Empties all the records stored in Local Storage
 */
export function ClearLocalStorage() {
  for (let key in P) localStorage.removeItem(key);
  for (let key in UP) localStorage.removeItem(key);
  sessionStorage.removeItem(P.session_token);
}

export function LoadUserData(d: PinCheckResponse) {
  localStorage.setItem(UP.user_id, d.id.toString());
  localStorage.setItem(UP.user_name, d.name);
  localStorage.setItem(UP.user_color, d.color);
  localStorage.setItem(UP.enable_back_office, d.enable_back_office.toString());
  localStorage.setItem(UP.enable_close_order, d.enable_close_order.toString());
  localStorage.setItem(UP.enable_edit_order, d.enable_edit_order.toString());
  localStorage.setItem(UP.enable_edit_menu, d.enable_edit_menu.toString());
  localStorage.setItem(UP.enable_accounting, d.enable_accounting.toString());
  localStorage.setItem(
    UP.enable_edit_parameters,
    d.enable_edit_parameters.toString()
  );
  localStorage.setItem(
    UP.enable_cancel_order,
    d.enable_cancel_order.toString()
  );
}

export function GetCurrentUser() {
  let cu = {
    id: "",
    name: "",
    color: "",
    enable_back_office: "true",
    enable_close_order: "true",
    enable_edit_order: "true",
    enable_edit_menu: "true",
    enable_accounting: "true",
    enable_edit_parameters: "true",
    enable_cancel_order: "true",
  };

  cu.id = localStorage.getItem(UP.user_id);
  cu.name = localStorage.getItem(UP.user_name);
  cu.color = localStorage.getItem(UP.user_color);
  cu.enable_back_office = localStorage.getItem(UP.enable_back_office);
  cu.enable_close_order = localStorage.getItem(UP.enable_close_order);
  cu.enable_edit_order = localStorage.getItem(UP.enable_edit_order);
  cu.enable_edit_menu = localStorage.getItem(UP.enable_edit_menu);
  cu.enable_accounting = localStorage.getItem(UP.enable_accounting);
  cu.enable_edit_parameters = localStorage.getItem(UP.enable_edit_parameters);
  cu.enable_cancel_order = localStorage.getItem(UP.enable_cancel_order);

  return cu;
}

export function LoadParameters(
  _p: RestaurantParameters,
  enable_reservation: boolean
) {
  localStorage.setItem(P.enable_delivery, _p.enable_delivery.toString());
  localStorage.setItem(P.enable_takeaway, _p.enable_takeaway.toString());
  localStorage.setItem(P.enable_autoprint, _p.enable_autoprint.toString());
  localStorage.setItem(P.enable_reservation, enable_reservation?.toString());
  localStorage.setItem(P.enable_stocks, _p.enable_stocks.toString());
  localStorage.setItem(
    P.enable_waiting_time,
    _p.enable_waiting_time.toString()
  );
  localStorage.setItem(
    P.supplement_delivery,
    _p.supplement_delivery.toString()
  );
}

export function LoadPrintingParameters(_p: RestaurantPrintingParameters) {
  localStorage.setItem(P.print_categories, _p.print_categories.toString());
  localStorage.setItem(P.print_logo, _p.print_logo.toString());
  localStorage.setItem(P.unify_products, _p.unify_products?.toString());
  localStorage.setItem(P.font_size, _p.font_size.toString());
  localStorage.setItem(P.line_height, _p.line_height.toString());
  localStorage.setItem(P.custom_text, _p.custom_text.toString());
  localStorage.setItem(
    P.print_categories_commands,
    _p.print_categories_commands.toString()
  );
  localStorage.setItem(P.font_size_commands, _p.font_size_commands.toString());
  localStorage.setItem(
    P.line_height_commands,
    _p.line_height_commands.toString()
  );
  localStorage.setItem(
    P.print_categories_commands,
    _p.print_categories_commands.toString()
  );
  localStorage.setItem(P.print_cancel, _p.print_cancel.toString());
  localStorage.setItem(P.print_edit, _p.print_edit.toString());
  localStorage.setItem(P.print_on_checkout, _p.print_on_checkout.toString());
  localStorage.setItem(P.print_attributes, _p.print_attributes.toString());
  localStorage.setItem(P.print_comments, _p.print_comments.toString());
  localStorage.setItem(P.printable_logo, _p.printable_logo?.toString());
  // localStorage.setItem(P.font_size_metadata, _p.font_size_metadata.toString());
  localStorage.setItem(
    P.print_menu_products,
    _p.print_menu_products.toString()
  );
}

/**
 * Returns stored value of a parameter
 * @param p Key of parameter
 * @returns Value of parameter
 */
export function ReadParameter(p: P | string) {
  const val = localStorage.getItem(p);
  return val == null ? "" : val;
}

/** =============================================== **
 **                  Vista cocina                   **
 ** =============================================== **/

/**
 * Establece el modo de la vista cocina
 * @param v
 * @returns
 */
export function SetView(v: "GLOBAL" | "SIMPLE") {
  localStorage.setItem(P.view, v);
  return;
}

/**
 * Devuelve el modo de la vista cocina
 * @returns Modo de la vista cocina
 */
export function GetView() {
  const val = localStorage.getItem(P.view);
  return val == null ? "SIMPLE" : val;
}

/**
 *
 * @param d
 * @returns
 */
export function SetDomain(d: string) {
  localStorage.setItem(P.domain, d);
  return;
}

/**
 *
 * @returns
 */
export function GetDomain() {
  const val = localStorage.getItem(P.domain);
  return val == null ? "" : val;
}

export function SetTables(
  t: { id: string; label: string; table_id: string }[]
) {
  const val = localStorage.setItem(P.tables, JSON.stringify(t));
  return val == null ? "" : val;
}

export function GetTables(): { id: string; label: string; table_id: string }[] {
  const val: { id: string; label: string; table_id: string }[] = JSON.parse(
    localStorage.getItem(P.tables) || "[]"
  );
  return val == null ? [] : val;
}

export function SetFilteredTables(t: string[]) {
  const val = localStorage.setItem(P.filtered_tables, JSON.stringify(t));
  return val == null ? "" : val;
}

export function GetFilteredTables(): string[] {
  const val: string[] = JSON.parse(
    localStorage.getItem(P.filtered_tables) || "[]"
  );
  return val == null ? [] : val;
}

export function SetType(t: string[]) {
  const val = localStorage.setItem(P.type, JSON.stringify(t));
  return val == null ? "" : val;
}

export function ReadType(): string[] {
  const val: string[] = JSON.parse(localStorage.getItem(P.type) || "[]");
  return val == null ? [] : val;
}

/** =============================================== **
 **                     Impresión                   **
 ** =============================================== **/

export function SetDefaultPrinters(t: RestaurantPrinter[]) {
  const val = localStorage.setItem(P.default_printers, JSON.stringify(t));
  return val == null ? "" : val;
}

export function GetDefaultPrinters(): RestaurantPrinter[] {
  const val: RestaurantPrinter[] = JSON.parse(
    localStorage.getItem(P.default_printers) || "[]"
  );
  return val == null ? [] : val;
}

export function SetSlug(t: string) {
  const val = localStorage.setItem(P.slug, t);
  return val == null ? "" : val;
}

export function GetSlug() {
  const val = localStorage.getItem(P.slug);
  return val == null ? "" : val;
}

export function SetTerminalIp(t: string) {
  document.cookie = `${P.terminal_ip}=${t};path=/;max-age=${
    60 * 60 * 24 * 28
  };`;
  return t;
}

export function GetTerminalIp() {
  const val = getCookie(P.terminal_ip);
  return { value: val == null ? "" : val, empty: val == null || val == "" };
}

export function DefaultPrinterIsReady(): boolean {
  const val: RestaurantPrinter[] = JSON.parse(
    localStorage.getItem(P.default_printers) || "[]"
  );
  return val.length > 0;
}

export function SetSuppressAutoprint(t: boolean) {
  document.cookie = `suppress_autoprint=${
    t ? "true" : "false"
  };path=/;max-age=${60 * 60 * 24 * 28};`;
  return t;
}

export function GetSuppressAutoprint() {
  const val = getCookie("suppress_autoprint");
  return { value: val == null ? "" : val, empty: val == null || val == "" };
}

/**
 * Indica la sala seleccionada en la pantalla sala
 * @param v
 * @returns
 */
export function SetSelectedSpace(v: string) {
  localStorage.setItem(P.selected_space, v);
  return;
}

/**
 * Devuelve la sala seleccionada
 * @returns Sala seleccionada
 */
export function GetSelectedSpace() {
  const val = localStorage.getItem(P.selected_space);
  return val == null || val == "null" ? undefined : val;
}

/** =============================================== **
 **                    Cached data                  **
 ** =============================================== **/
export function SaveCategoriesCache(categories: Category[]) {
  localStorage.setItem(P.cached_categories, JSON.stringify(categories));
  return;
}

export function ReadCategoriesCache(): Category[] {
  const val: Category[] = JSON.parse(
    localStorage.getItem(P.cached_categories) || "[]"
  );

  return val;
}

export function SaveProductsCache(products: OverviewProductResponse[]) {
  localStorage.setItem(P.cached_products, JSON.stringify(products));
  return;
}

export function ReadProductsCache(): OverviewProductResponse[] {
  const val: OverviewProductResponse[] = JSON.parse(
    localStorage.getItem(P.cached_products) || "[]"
  );

  return val;
}

export function SaveCustomersCache(customers: Customer[]) {
  localStorage.setItem(P.cached_customers, JSON.stringify(customers));
  return;
}

export function ReadCustomersCache(): Customer[] {
  const val: Customer[] = JSON.parse(
    localStorage.getItem(P.cached_customers) || "[]"
  );

  return val;
}

export function SavePrintersCache(printers: {
  printers: RestaurantPrinter[];
  delivery_printers: CategoryPrinter[];
  takeaway_printers: CategoryPrinter[];
  custom_printers: CategoryPrinter[];
}) {
  localStorage.setItem(P.cached_printers, JSON.stringify(printers));
  return;
}

export function ReadPrintersCache(): {
  printers: RestaurantPrinter[];
  delivery_printers: CategoryPrinter[];
  takeaway_printers: CategoryPrinter[];
  custom_printers: CategoryPrinter[];
} {
  if (localStorage.getItem(P.cached_printers) == null) return undefined;
  const val: {
    printers: RestaurantPrinter[];
    delivery_printers: CategoryPrinter[];
    takeaway_printers: CategoryPrinter[];
    custom_printers: CategoryPrinter[];
  } = JSON.parse(localStorage.getItem(P.cached_printers));

  return val;
}

export function SaveSpacesCache(spaces: Space[]) {
  localStorage.setItem(P.cached_spaces, JSON.stringify(spaces));
  return;
}

export function ReadSpacesCache(): Space[] {
  if (localStorage.getItem(P.cached_spaces) == null) return [];
  const val: Space[] = JSON.parse(localStorage.getItem(P.cached_spaces));

  return val;
}
