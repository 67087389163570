import {
  faShippingFast,
  faStore,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { SCHEDULE_TYPES } from "./Types";

const API_VERSION = "v1/";
const LOCAL_API = "http://localhost:8000/api/";
const VPS_PRE_API = "https://api.vps.fooddynamic.es/api/";
const VPS_PRO_API = "https://preapi.vps.fooddynamic.es/api/";
export const API_ENDPOINT = VPS_PRO_API + API_VERSION;
export const APP_VERSION = "v2.0.4";
export const CLIENT_APP_URL = "https://app.fooddynamic.es";

/* Comandas */
export const COMMAND_TAKEAWAY_ICON: IconDefinition = faStore;
export const COMMAND_DELIVERY_ICON: IconDefinition = faShippingFast;

/* Manejo de fechas */
export const dateOptions: Intl.DateTimeFormatOptions = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};

/* Tipos de horarios */
export const scheduleTypeName: { [key in SCHEDULE_TYPES]: string } = {
  BUSINESS: "de reserva",
  DELIVERY: "de reparto",
  TAKEAWAY: "de recogida",
};

/* Dias de la semana */
export enum WEEK_DAYS {
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY",
}

export const weekDaysName: { [key in WEEK_DAYS]: string } = {
  MONDAY: "Lunes",
  TUESDAY: "Martes",
  WEDNESDAY: "Miércoles",
  THURSDAY: "Jueves",
  FRIDAY: "Viernes",
  SATURDAY: "Sábado",
  SUNDAY: "Domingo",
};

export const hourRange_REGEXP: RegExp =
  /^([01]?[0-9]|2[0-3])(:[0-5][0-9])?-([01]?[0-9]|2[0-3])(:[0-5][0-9])?$/i;

export const hourMinutesRange_REGEXP: RegExp =
  /^([01]?[0-9]|2[0-3]):[0-5][0-9]-([01]?[0-9]|2[0-3]):[0-5][0-9]$/i;

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
